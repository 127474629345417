.aboutUs {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 3rem;
  &-content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 78.75rem;
    font-size: 1.5rem;
    letter-spacing: 0.66px;
    margin: 1rem 0;
  }
  &__title {
    color: #000;
    font-size: 44px;
    font-family: Questrial;
    font-weight: 700;
    line-height: 56.6px;
    letter-spacing: 0.66px;
    width: 100%;
    padding-left: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .aboutUs {
    &-title {
      color: #000;
      font-size: 44px;
      font-family: Questrial;
      font-weight: 700;
      line-height: 56.6px;
      letter-spacing: 0.66px;
      padding: 0.5rem;
    }
  }
}
