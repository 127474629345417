.footer {
  display: flex;
  padding: 33px 0px 45px 0px;
  flex-direction: column;
  align-items: flex-start;
  background-color: $background-color;
  padding: 33px 20px 31.5px 20px;
  &__info {
    display: flex;
    flex-direction: column;
  }

  &__mission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 23.62px;
    width: 100%;
  }
  &__text {
    color: $font-nav;
    font-size: 15.75px;
    font-family: Questrial;
    line-height: 27.75px;
    letter-spacing: 0.63px;
    // margin-bottom: 41.99px;
  }
  &__title {
    color: $font-nav;
    font-size: 17.6px;
    font-weight: 700;
    line-height: 22.64px;
    letter-spacing: 0.66px;
  }
  &__menu {
    display: flex;
    // height: 278.08px;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
    gap: 23.63px;
    flex-shrink: 0;
  }
  // &__menu-title {
  //   color: #fff;
  //   font-size: 17.6px;
  //   font-weight: 700;
  //   line-height: 22.64px;
  //   letter-spacing: 0.66px;
  //   margin-bottom: 23.63px;
  // }
  &-items {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    gap: 23.63px;
    // height: 30%;
    // border: 1px solid red;
    width: 100%;
    height: 7rem;
    padding: 1rem;
    justify-content: space-evenly;
  }
  &__menu__item {
    color: $font-nav;
    font-size: 14.7px;
    line-height: 25.9px;
    letter-spacing: 0.63px;
  }
  &__credit-cards {
    height: 79.5px;
    width: 14rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 42px;
  }
  &__credit-card {
    width: 38px;
    height: 24px;
    margin: 0.25rem;
    flex-shrink: 0;
  }
  &__copy-right {
    color: $font-nav;
    text-align: center;
    font-size: 11.55px;
    font-family: Questrial;
    line-height: 19.25px;
    letter-spacing: 0.735px;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .footer {
    display: flex;
    align-items: center;
    &__info {
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      max-width: 78.75rem;
      padding: 0rem 3.28125rem 3.54313rem 3.28125rem;
      align-items: center;
      gap: 1.96813rem;
    }
    &__mission {
      display: flex;
      height: 14.0725rem;
      padding: 0.125rem 0rem 0rem 0rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.46563rem;
      flex: 1 0 0;
    }
    &__menu {
      width: 30%;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
    }
    &__credit-cards {
      width: 100%;
    }
  }
}
