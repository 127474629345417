.filtered {
  background-color: white;
  &-list {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    padding: 2rem 0;
  }
}
