.transSucess {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 3rem;
  &-content {
    display: flex;
    flex-direction: column;

    max-width: 78.75rem;
    font-size: 1.5rem;
    letter-spacing: 0.66px;
    margin-bottom: 2rem;
  }
  &-subtitle {
    color: #000;
    font-size: 30px;
    font-family: Questrial;
    font-weight: 700;
    letter-spacing: 0.66px;
    margin-bottom: 1rem;
  }
  &-title {
    color: #000;
    font-size: 44px;
    font-family: Questrial;
    font-weight: 700;
    line-height: 56.6px;
    letter-spacing: 0.66px;
    margin-bottom: 1rem;
  }
}
