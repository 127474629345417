.product {
  display: flex;
  height: auto;
  padding: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2.8125rem;
  flex-shrink: 0;
  background-color: #fff;
  &__images {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__video {
    width: 18.75rem;
    height: 18.75rem;
  }
  &__name {
    color: #000;
    font-size: 2.0625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.65313rem;
    letter-spacing: 0.04125rem;
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.40313rem;
  }
  &__prices {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
  &__regprice {
    color: rgba(245, 8, 8, 0.75);
    font-size: 1.05rem;
    font-family: Questrial;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25938rem;
    letter-spacing: 0.08531rem;
    text-decoration: line-through;
  }
  &__saleprice {
    color: #000;
    font-size: 1.05rem;
    font-family: Questrial;
    font-style: normal;
    font-weight: 400;
    line-height: 1.55rem;
    letter-spacing: 0.08531rem;
  }
  &__img {
    // width: 18.75rem;
    height: 30rem;
    flex-shrink: 0;
    border-radius: 1.25rem;
  }
  &__shippingDisclaimer {
    display: flex;
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.65625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.09375rem;
    letter-spacing: 0.04594rem;
  }
  &__addToCart {
    display: inline-flex;
    padding-right: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.40313rem;
  }
  &__quantityTitle {
    color: rgba(0, 0, 0, 0.75);
    font-size: 0.85313rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25938rem;
    letter-spacing: 0.02625rem;
    margin-bottom: 0.47rem;
  }
  &__actions {
    width: 8.75rem;
    height: 2.95313rem;
    flex-shrink: 0;
    border-radius: 1.625rem;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.55);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__addToCartBtn {
    width: 20.28125rem;
    height: 2.95313rem;

    background-color: #000;
    border-radius: 2.5rem;
    letter-spacing: 0.02625rem;
    box-shadow: 0px 0px 0px 1px #000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.7rem 0;
  }
  &__descriptionTxt {
    color: rgba(0, 0, 0, 0.75);
    font-size: 0.98438rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.73438rem;
    letter-spacing: 0.03938rem;
    margin-bottom: 2rem;
    &--benefit {
      color: #000;
      font-size: 1.5rem;
      font-style: bold;
      font-weight: 800;
      line-height: 1.55rem;
      letter-spacing: 0.08531rem;
      margin-bottom: 1rem;
    }
  }
  &__description {
    margin-bottom: 1rem;
    list-style: none;
  }
  &__serving {
    margin-bottom: 1rem;
  }
  &__ingredients-title {
    color: rgba(0, 0, 0, 0.75);
    font-size: 2rem;
    font-style: bold;
    font-weight: 800;
    line-height: 1.73438rem;
    letter-spacing: 0.03938rem;
    margin-bottom: 1rem;
  }
  &__reviews {
    border-top: solid 1px #bbbfbf;
    margin: 2rem 0;
    padding: 6rem 0;
  }
  &__social {
    margin: 3rem 0;

    &__rate-name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &-title {
      color: rgba(0, 0, 0, 0.75);
      font-size: 1rem;
      font-style: bold;
      font-weight: 800;
      line-height: 1.73438rem;
      letter-spacing: 0.03938rem;
    }
    &-location {
      letter-spacing: 0.03938rem;
    }
    &-locationTxt {
      letter-spacing: 0.03938rem;
      line-height: 1.73438rem;
    }
  }
}

@media (min-width: 1024px) {
  .product {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    &__info {
      width: 50%;
      padding-left: 5rem;
      max-width: 59rem;
    }
    &__descriptionTxt {
      font-size: 1.05rem;
    }
  }
}
