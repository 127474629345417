.cart {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 23rem;
  &-title {
    font-size: 2rem;
    margin: 1rem;
  }
  &-items {
    width: none;
  }

  &-item {
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;

    padding: 2rem 0;
    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.1rem solid #eeeeee;
    }
  }

  &-itemInfo {
    &__img {
      width: 5rem;
      // height: 8rem;
    }
    &__name {
      margin: 0.5rem;
    }
    &__unitprice {
      margin: 0.5rem;
    }
  }
  &-itemquantity {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0.5rem;

    &__empty {
      margin-left: 1rem;
    }
    &__totalprice {
      margin-right: 1rem;

      width: 5rem;
    }
  }
  &-subtotal {
    width: 100%;
    display: flex;
    justify-content: center;

    align-items: center;
    font-size: 1.5rem;
    padding: 2rem 0;
    &__amt {
      margin-left: 1rem;
      color: #757474;
    }
  }
}

@media screen and (min-width: 1024px) {
  .cart {
    &-item {
      flex-direction: row;
      align-items: center;
      width: 50rem;
    }
    &-itemquantity {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0.5rem 3rem 0 auto;
      &__totalprice {
        margin: 0;
      }
    }
    &-checkoutCTA {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 2rem;
    }
    &-subtotal {
      width: 13rem;
    }
  }
}

@media screen and (min-width: 1920px) {
  .cart {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30rem;
    &-item {
      width: 70rem;
    }
  }
}
